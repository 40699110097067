








































































































































































































































































































import Address from "@/models/Address"
import AddressService from "@/services/AddressService"
import SessionModule from "@/store/SessionModule"
import {Component, Vue} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"

@Component
export default class AddressTab extends Vue {

  sessionModule: SessionModule = getModule(SessionModule)
  addresses: Address[] =[]

  created() {
    this.refresh()
  }

  refresh() {
    AddressService.getAddressByClient(this, this.addresses, this.sessionModule.session.user.id!)
  }

}
