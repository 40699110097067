import {Vue} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import Order, {OrderType} from "@/models/Order"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import SnackbarModule from "@/store/SnackbarModule"
import JsonTool from "@/services/tool/JsonTool"
import { AxiosResponse } from "axios"

export default class OrderService {

	static async getOrdersByClientId(component: Vue, orders: Order[], userId: number) {
		// @ts-ignore
		component.loading = true
		try {
			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/api/orders/client/" + userId, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			let list = JsonTool.jsonConvert.deserializeArray(response.data, Order)
			orders.splice(0, orders.length)
			list.forEach(p => orders.push(p))
			// @ts-ignore
			component.loading = false
		} catch (e) {
			console.log(e)
			getModule(SnackbarModule).makeToast("Error al ubicar los pedidos")
			// @ts-ignore
			component.loading = false
		}
	}

	static async getMyOrders(
		component: Vue, orders: Order[], page: number, size: number, userId: number | null, search: string | null,
		orderType: OrderType | null, clientId: number | undefined, start: string | null, end: string | null
	) {
		(<any>component).loading = true
		try {
			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/orders", {
				params: { page, size, userId, search, orderType, clientId, start, end },
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			let list = JsonTool.jsonConvert.deserializeArray(response.data, Order)
			orders.splice(0, orders.length)
			list.forEach(p => orders.push(p))
			// @ts-ignore
			component.totalItems = Number(response.headers["x-total-count"]);
			(<any>component).loading = false
		} catch(e) {
			console.log(e)
			getModule(SnackbarModule).makeToast("No se han podido obtener los pedidos");
			(<any>component).loading = false
		}
	}

	static async getMyOrdersByUser(
		component: Vue, orders: Order[], page: number, size: number, userId: number, search: string | null,
		orderType: OrderType | null, clientId: number | undefined, start: string | null, end: string | null
	) {
		(<any>component).loading = true
		try {
			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/users/"+ userId +"/orders", {
				params: { page, size, search, orderType, clientId, start, end },
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			let list = JsonTool.jsonConvert.deserializeArray(response.data, Order)
			orders.splice(0, orders.length)
			list.forEach(p => orders.push(p))
			// @ts-ignore
			component.totalItems = Number(response.headers["x-total-count"]);
			(<any>component).loading = false
		} catch(e) {
			console.log(e)
			getModule(SnackbarModule).makeToast("No se han podido obtener los pedidos");
			(<any>component).loading = false
		}
	}

	static async postOrder(component: Vue, order: Order, clientId: number, send: boolean = false) {
		// @ts-ignore
		component.loading = true
		try {
			const response: AxiosResponse = await component.axios.post(ConstantTool.BASE_URL + "/api/clients/"+ clientId +"/orders/",
				order, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})

			const orderResponse = JsonTool.jsonConvert.serializeObject(response.data, Order)

			if (send) { await this.sendOrder(component, orderResponse.id!) }
			// @ts-ignore
			component.loading = false

			// @ts-ignore
			if (response.status == 201) { component.dialog = true }

			getModule(SnackbarModule).makeToast("Generado un pedido");
		} catch (e) {
			console.log(e)
			// @ts-ignore
			component.loading = false
			getModule(SnackbarModule).makeToast("Error al generar un pedido");
		}
	}

	static async patchOrder(component: Vue, order: Order) {
		// @ts-ignore
		component.loading = true
		try {
			await component.axios.patch(ConstantTool.BASE_URL + "/api/orders/" + order.id!, order, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			// @ts-ignore
			component.loading = false
			getModule(SnackbarModule).makeToast("Editado un pedido");
			await component.$router.push("/profile/settings/personal")
		} catch (err) {
			console.log(err)
			// @ts-ignore
			component.loading = false
			getModule(SnackbarModule).makeToast("Error al editar un pedido");
		}
	}

	static async sendOrder(component: Vue, id: number) {
		// @ts-ignore
		component.loading = true
		try {
			await component.axios.patch(ConstantTool.BASE_URL + "/api/orders/" + id + "/send", null, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			// @ts-ignore
			component.loading = false
			getModule(SnackbarModule).makeToast("Pedido enviado con exito!")
			if (component.$route.name != "Mis Pedidos") {
				await component.$router.push("/my-orders");
			} else {
				// @ts-ignore
				component.refresh()
			}
		} catch (err) {
			console.log(err)
			// @ts-ignore
			component.loading = true
			getModule(SnackbarModule).makeToast("Error al enviar el pedido")
		}
	}

	static async cloneOrder(component: Vue, order: Order) {
		// @ts-ignore
		component.loading = true
		order.id = undefined
		try {
			await component.axios.post(ConstantTool.BASE_URL + "/api/clients/" + order.client!.id! + "/orders", order, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			// @ts-ignore
			component.loading = false
			getModule(SnackbarModule).makeToast("Pedido clonado con exito!")
		} catch (err) {
			console.log(err)
			// @ts-ignore
			component.loading = true
			getModule(SnackbarModule).makeToast("Error al clonar el pedido")
		}
	}

	static async getDrafts(component: Vue, orders: Order[]) {
		(<any>component).loading = true
		try {
			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/api/orders/draft", {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			let list = JsonTool.jsonConvert.deserializeArray(response.data, Order)
			orders.splice(0, orders.length)
			list.forEach(p => orders.push(p));
			// @ts-ignore
			(<any>component).loading = false
		} catch(e) {
			console.log(e)
			getModule(SnackbarModule).makeToast("No se han podido obtener los borradores");
			(<any>component).loading = false
		}
	}

	static async draftOrder(component: Vue, order: Order) {
		// @ts-ignore
		component.loading = true
		try {
			const response: any = await component.axios.patch(ConstantTool.BASE_URL + "/api/orders/draft", order, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			const orderResponse = JsonTool.jsonConvert.deserializeObject(response.data, Order);
			// @ts-ignore
			component.loading = false
			// @ts-ignore
			component.order.id = orderResponse.id
		} catch (e) {
			console.log(e)
			// @ts-ignore
			component.loading = false
		}
	}

	static async getOrder(component: Vue, id: number) {
		// @ts-ignore
		component.loading = true
		try {
			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/public/orders/" + id, {
				headers: {Authorization: getModule(SessionModule).session.token}
			});
			// @ts-ignore
			component.order = JsonTool.jsonConvert.deserializeObject(response.data, Order);
			// @ts-ignore
			component.loading = false;
		} catch (e) {
			getModule(SnackbarModule).makeToast("No se pudo obtener el pedido")
		}
	}

	static async deleteOrder(component: Vue, id: number) {
		// @ts-ignore
		component.loading = true
		try {
			const response: any = await component.axios.delete(ConstantTool.BASE_URL + "/api/orders/" + id, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			// @ts-ignore
			component.loading = false
			getModule(SnackbarModule).makeToast("Su pedido ha sido eliminado.");
		} catch (e) {
			console.log(e)
			getModule(SnackbarModule).makeToast("Ha ocurrido un error al eliminar el pedido.")
		}
	}

	static async getOrders(
		component: Vue, orders: Order[], page: number, size: number, search: string | null,
		clientId: number | null, companyId: number | null,
		start: string | null, end: string | null
	) {
		(<any>component).loading = true
		try {
			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/api/orders", {
				params: { page, size, search, clientId, companyId, start, end },
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			let list = JsonTool.jsonConvert.deserializeArray(response.data, Order)
			orders.splice(0, orders.length)
			list.forEach(p => orders.push(p))
			// @ts-ignore
			component.totalItems = Number(response.headers["x-total-count"]);
			(<any>component).loading = false
		} catch(e) {
			console.log(e)
			getModule(SnackbarModule).makeToast("No se han podido obtener los pedidos");
			(<any>component).loading = false
		}
	}

}