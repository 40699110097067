var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-0 py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-progress-linear',{staticClass:"my-2",attrs:{"indeterminate":_vm.loading}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"disable-sort":"","mobile-breakpoint":0,"height":"400px","fixed-header":"","headers":_vm.headers,"items":_vm.orders,"hide-default-footer":"","no-data-text":_vm.loading ? _vm.lang.searching : _vm.lang.noResults},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.ref",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium",attrs:{"hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.filterByProduct,"append-icon":"mdi-magnify"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","outlined":"","width":"100%","min-height":"40px","color":"#9e9e9e"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.lang.filterByDate)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_vm._v("mdi-calendar-clock-outline")])],1)]}}],null,true)},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-date-picker',{attrs:{"year-icon":"mdi-calendar-blank","prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","multiple":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-card-actions',[_c('v-sheet',{staticClass:"mx-auto px-8 py-2",attrs:{"depressed":"","color":"pb-grey"},on:{"click":function($event){_vm.dates = []}}},[_vm._v(_vm._s(_vm.lang.clearFilters))]),_c('v-sheet',{staticClass:"mx-auto px-8 py-2",attrs:{"depressed":"","color":"pb-success"},on:{"click":_vm.refresh}},[_vm._v(_vm._s(_vm.lang.search))])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.state",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByStatus,"items":_vm.orderTypes,"outlined":"","autocomplete":"null","item-text":"name","item-value":"value"},model:{value:(_vm.orderType),callback:function ($$v) {_vm.orderType=$$v},expression:"orderType"}})],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.totalAmount",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByClient,"items":_vm.clients,"outlined":"","item-text":"name","item-value":"name","return-object":"","autocomplete":"null"},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.reference)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd MMMM yyyy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('OrderStatusComponent',{attrs:{"orderType":item.orderType}})],1)]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.totalPvp)+"€ ")])]}},{key:"footer",fn:function(){return [_c('v-divider',{staticClass:"mb-4"}),_c('div',{staticClass:"d-none d-sm-flex align-center text-center justify-space-around"},_vm._l((_vm.indicators),function(item,key){return _c('div',{key:key},[_c('span',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v("mdi-square")]),_vm._v(" "+_vm._s(item.name)+" ")],1)])}),0),_c('v-divider',{staticClass:"mt-4"})]},proxy:true}])}),_c('v-progress-linear',{staticClass:"my-2",attrs:{"indeterminate":_vm.loading}}),(_vm.orders.length !== 0)?_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)],1),_c('OrderDetailsDialog',{attrs:{"dialog":_vm.dialog,"order":_vm.order},on:{"close":function($event){return _vm.closeDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }