import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import Client from "@/models/Client";
import JsonTool from "@/services/tool/JsonTool";
import AddressService from "@/services/AddressService";
import Address from "@/models/Address";

export default class ClientService {

    static async postClient(component: Vue, client: Client, address: Address) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/clients/", client, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            // @ts-ignore
            component.client.id = JsonTool.jsonConvert.deserializeObject(response.data, Client).id

            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
            // @ts-ignore
            //component.closeDialog()
            getModule(SnackbarModule).makeToast("El cliente ha sido creado");
        } catch (e) {
            console.log(e)
            // @ts-ignore
            component.loading = false

            getModule(SnackbarModule).makeToast("Error al crear el cliente");
        }
    }

    static async patchClient(component: Vue, client: Client, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/@me/clients/" + id,
                client, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("El cliente ha sido actualizado");
        } catch (e) {
            console.log(e)
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al actualizar el cliente");
        }
    }

    static async getClient(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response: any = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/clients/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.client = JsonTool.jsonConvert.deserializeObject(response.data, Client);
            // @ts-ignore
            component.loading = false;
        } catch (e) {
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se pudo obtener el cliente")
        }
    }

    static async getClients(component: Vue, clients: Client[], page: number, size: number, search: string, all: boolean = false, active: boolean = true) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/clients", {
                params: { page, size, search, all, active },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Client)
            clients.splice(0, clients.length)
            list.forEach(p => clients.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"]);
            (<any>component).loading = false
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes");
            (<any>component).loading = false
        }
    }

    static async getMyClients(component: Vue, clients: Client[], page: number, size: number, search: string, active: boolean = true, userId: number) {
        (<any>component).loading = true
        try {
            const response: any = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/users/"+ userId +"/clients", {
                params: { page: page, size: size, search, active },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Client)
            clients.splice(0, clients.length)
            list.forEach(p => clients.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"]);
            (<any>component).loading = false
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes");
            (<any>component).loading = false
        }
    }

    static async deleteClient(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            console.log(id)
            await component.axios.delete(ConstantTool.BASE_URL + "/api/@me/clients/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            component.$router.push("/clients")
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("El cliente ha sido eliminado de manera exitosa!")
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se han eliminar el cliente")
        }
    }

    static async activateClient(component: Vue, id: number, enable: boolean) {
        let formData = new FormData()
        formData.set("active", enable ? "true" : "false")
        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/@me/clients/"+ id +"/activate", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se han cambiar el estado del cliente")
        }
    }

}